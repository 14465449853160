<template>
  <div class="dealer">
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete <b>{{ delParentDealer.name }}</b> from
          list?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doDelParentDealer()">
            Yes
          </v-btn>
          <v-btn text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="associatedCarBrandsDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Update Associated Car Brands
        </v-card-title>
        <v-virtual-scroll
          :bench="benched"
          :items="dealerCarBrandsList"
          height="300"
          item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.carBrandId">
              <v-list-item-content>
                <img
                  style="height:50px; width: 50px; flex:none"
                  v-if="item.carBrandImgUrl != null"
                  :src="item.carBrandImgUrl"
                />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ item.carBrandName }}</strong>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  fab
                  small
                  depressed
                  color="red"
                  @click="deleteFromCarBrandList(item)"
                >
                  <v-icon medium color="white">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>

        <v-card-text>
          <v-autocomplete
            v-model="selectedCarBrand"
            :items="carBrandSearchEntries"
            :search-input.sync="search"
            :loading="isLoading"
            prepend-icon="mdi-database-search"
            return-object
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-text>
          <v-btn
            style="float:right"
            small
            depressed
            color="primary"
            @click="addDealerCarBrand(selectedCarBrand)"
          >
            <v-icon medium color="white">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              doUpdateDealerCarBrands(editDealer.dealerId, dealerCarBrandsList)
            "
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Add dialog -->
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add {{ addDealer.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px;">
            <v-row>
              <v-col>
                <v-row>
                  <v-text-field
                    v-model="addDealer.name"
                    label="Name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-file-input
                    label="Logo"
                    v-model="addDealer.logoPath"
                    clearable
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                  ></v-file-input>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="addDealer.description"
                    label="Description"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.mobilePhone"
                    label="Contact"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.address"
                    label="Address"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.uenNum"
                    label="UEN"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.facebookUrl"
                    label="Facebook Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="addDealer.websiteUrl"
                    label="Website Url"
                  ></v-text-field>
                </v-row>
                <!-- <v-row>
                  <v-text-field
                    v-model="addDealer.creditBalance"
                    label="Credit Balance"
                  ></v-text-field>
                </v-row> -->
                <!-- <v-row>
                  <v-switch
                    label="Authorized Dealer"
                    v-model="editDealer.authorizedFlag"
                  ></v-switch>
                </v-row>
                <v-row>
                  <v-switch
                    label="Hide Car"
                    v-model="editDealer.hideCarFlag"
                  ></v-switch>
                </v-row> -->
              </v-col>

              <v-col>
                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;"
                  >
                    <v-file-input
                      label="Branding Banner"
                      v-model="addDealer.primaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;;"
                  >
                    <v-file-input
                      label="Side Banner"
                      v-model="addDealer.secondaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;;"
                  >
                    <v-file-input
                      label="Large Top Banner"
                      v-model="addDealer.tertiaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <div style="border: 1px dotted black; padding: 12px;">
                      <ParentDealerGroupInput
                        :parentDealerId="addDealer.dealerId"
                      />
                    </div>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-btn
                text
                color="primary"
                @click="showDealerCarBrands(editDealer.dealerId)"
              >
                Associated Car Brands
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="doAddDealer(addDealer)"
                >Submit</v-btn
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px;">
            <v-row>
              <v-col>
                <v-row>
                  <v-text-field
                    v-model="editDealer.name"
                    label="Name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="editDealer.description"
                    label="Description"
                    :rows="1"
                    auto-grow
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.mobilePhone"
                    label="Contact"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.address"
                    label="Address"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.uenNum"
                    label="UEN"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.facebookUrl"
                    label="Facebook Url"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editDealer.websiteUrl"
                    label="Website Url"
                  ></v-text-field>
                </v-row>
                <!-- <v-row>
                  <v-text-field
                    v-model="editDealer.creditBalance"
                    label="Credit Balance"
                  ></v-text-field>
                </v-row> -->
                <!-- <v-row>
                  <v-switch
                    label="Authorized Dealer"
                    v-model="editDealer.authorizedFlag"
                  ></v-switch>
                </v-row>
                <v-row>
                  <v-switch
                    label="Hide Car"
                    v-model="editDealer.hideCarFlag"
                  ></v-switch>
                </v-row> -->
              </v-col>

              <v-col>
                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;"
                  >
                    <img
                      style="height: 50px;
    width: fit-content;"
                      v-if="primaryListingBannerDisplay != null"
                      :src="primaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Branding Banner"
                      v-model="editDealer.primaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;;"
                  >
                    <img
                      style="height: 50px;
    width: fit-content;"
                      v-if="secondaryListingBannerDisplay != null"
                      :src="secondaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Side Banner"
                      v-model="editDealer.secondaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    style="    display: flex;
    flex-flow: column-reverse;;"
                  >
                    <img
                      style="height: 50px;
    width: fit-content;"
                      v-if="tertiaryListingBannerDisplay != null"
                      :src="tertiaryListingBannerDisplay"
                    />
                    <v-file-input
                      label="Large Top Banner"
                      v-model="editDealer.tertiaryListingBannerPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div style="border: 1px dotted black; padding: 12px;">
                      <ParentDealerGroupInput
                        :parentDealerId="editDealer.dealerId"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-btn
                text
                color="primary"
                @click="showDealerCarBrands(editDealer.dealerId)"
              >
                Associated Car Brands
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shortcutEditDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editDealer.status"
            :items="dealerStatusList"
            item-value="value"
            item-text="text"
            label="Dealer Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editNameDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Name
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editDealer.name" label="Dealer Name">{{
            editDealer.name
          }}</v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPhotoDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Profile Picture
        </v-card-title>
        <v-card-text>
          <v-file-input
            label="Logo"
            v-model="imgFile"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCrshortcutEditDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} credit balance
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            type="number"
            prefix="$"
            v-model="editDealer.creditBalance"
            label="Credit balance"
            >{{ editDealer.creditBalance }}</v-text-field
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDescriptionDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Description
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editDealer.description"
            :rows="2"
            auto-grow
            label="Description"
            >{{ editDealer.description }}</v-textarea
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="assignDealerTypeDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign {{ assignDealerTypeData.name }} Type
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="type in dealerTypeList"
            :key="type.id"
            v-model="assignDealerTypeData.dealerTypeId"
            :label="type.dealerType"
            :value="type.id"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="showDealerCarBrands(assignDealerTypeData.dealerId)"
          >
            Associated Car Brands
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="doAssignDealerTypes"
            :disabled="assignDealerTypeData.dealerTypeId.length === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="assignAccountManagerDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign Account Manager
        </v-card-title>
        <v-card-text>
          <v-select
            :items="accountManagerList"
            v-model="assignAccountManagerData.accountManagerId"
            item-value="id"
            item-text="name"
            label="Select Account Manager"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="doAssignAccountManager"
            :disabled="assignAccountManagerData.accountManagerId === ''"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDealer()"
          v-model="dealerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="dealerStatus"
          :items="dealerStatusList"
          item-value="value"
          item-text="text"
          label="Car Status"
        >
        </v-select>
      </v-col> -->
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDealer()"
          @keyup.enter.native="searchDealer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="dealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-btn class="mx-2" dark small @click="editNameButtonClick(item)">
          {{ item.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.logoPath`]="{ item }">
        <v-btn class="mx-2" dark small @click="editPhotoButtonClick(item)">
          <img
            style="height:50px;"
            v-if="item.logoPath != null"
            :src="item.logoPath"
          />
        </v-btn>
      </template>
      <!-- <template v-slot:[`item.status`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          :color="
            item.status === 'REJECTED'
              ? 'error'
              : item.status === 'PENDING_APPROVAL'
              ? 'warning'
              : 'primary'
          "
          @click="editStatusButtonClick(item)"
        >
          {{ item.status }}
        </v-btn>
      </template> -->
      <!-- <template v-slot:[`item.accountManager`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignAccountManagerClick(item)">
          {{ item.accountManagerName ? item.accountManagerName : "null" }}
        </v-btn>
      </template> -->
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="editButtonClick(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="red"
          @click="deleteButtonClick(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";
import ParentDealerGroupInput from "@/components/ParentDealerGroupInput";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  components: {
    ParentDealerGroupInput,
  },
  data() {
    return {
      carBrandSearchEntries: null,
      editDialog: false,
      selectedCarBrand: null,
      isLoading: false,
      dealerCarBrandsList: [],
      benched: 0,
      entries: [],
      search: null,
      dealerSearch: "",
      dealerStatus: "",
      imgFile: null,
      imgUrl: null,
      primaryListingBannerDisplay: null,
      secondaryListingBannerDisplay: null,
      tertiaryListingBannerDisplay: null,
      pageCount: 0,
      associatedCarBrandsDialog: false,
      shortcutEditDialog: false,
      editNameDialog: false,
      editPhotoDialog: false,
      editCrshortcutEditDialog: false,
      editDescriptionDialog: false,
      assignAccountManagerDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Logo", value: "logoPath" },
        { text: "Phone", value: "mobilePhone" },
        { text: "Address", value: "address" },
        { text: "UEN", value: "uenNum" },
        // { text: "Status", value: "status" },
        // { text: "Manager", value: "accountManager" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      dealerList: [],
      addDialog: false,
      addDealer: {
        name: null,
        mobilePhone: null,
        address: null,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
        description: null,
        hideCarFlag: null,
        authorizedFlag: null,
        primaryListingBannerPath: null,
        secondaryListingBannerPath: null,
        tertiaryListingBannerPath: null,
        facebookUrl: null,
        websiteUrl: null,
      },
      editDealer: {
        dealerId: null,
        name: null,
        mobilePhone: null,
        address: null,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
        description: null,
        hideCarFlag: null,
        authorizedFlag: null,
        primaryListingBannerPath: null,
        secondaryListingBannerPath: null,
        tertiaryListingBannerPath: null,
        facebookUrl: null,
        websiteUrl: null,
      },
      dealerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      platformList: [],
      dealerTypeList: [],
      accountManagerList: [],
      assignDealerTypeDialog: false,
      assignDealerTypeData: {
        dealerTypeId: [],
        name: "",
        dealerId: "",
      },
      assignAccountManagerData: {
        accountManagerId: "",
        dealerId: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
      delParentDealer: {
        id: "",
        name: "",
      },
      deleteDialog: false,
    };
  },
  computed: {
    // carBrandSearchEntries() {
    //   const currectDealerCarBrandIds = this.dealerCarBrandsList.map(
    //     x => x.carBrandId
    //   );
    //   var d = this.entries
    //     .filter(x => currectDealerCarBrandIds.indexOf(x.id) < 0)
    //     .map(x => ({
    //       text: x.name,
    //       value: x.id,
    //       disabled: false,
    //       logoPath: x.logoPath,
    //       name: x.name,
    //       id: x.id,
    //     }));
    //   return d;
    // },
  },
  watch: {
    editDialog(newVal) {
      if (!newVal) {
        this.resetEditItemInfo();
      }
    },
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      //   getAxios(
      //     `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?searchContent=${val}`
      //   )
      //     .then(res => {
      //       this.entries = res.data.data.content;
      //     })
      //     .catch(err => {
      //       console.log(err);
      //     })
      //     .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchDealerList();
    // this.fetchPlatforms();
    // this.fetchDealerTypes();
    // this.fetchAccountManagers();
  },
  methods: {
    // chooseImage() {
    //   this.editDealer.logoName = this.imgFile.name;
    //   this.imgUrl = URL.createObjectURL(this.imgFile);
    // },
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchDealerList();
    },
    searchDealer() {
      this.fetchDealerList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealerList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    editStatusButtonClick(item) {
      this.setEditItemInfo(item);

      this.shortcutEditDialog = true;
    },
    editCreditButtonClick(item) {
      this.setEditItemInfo(item);

      this.editCrshortcutEditDialog = true;
    },
    editPhotoButtonClick(item) {
      this.setEditItemInfo(item);
      this.editPhotoDialog = true;
    },
    editNameButtonClick(item) {
      this.setEditItemInfo(item);
      this.editNameDialog = true;
    },
    editDescriptionButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDescriptionDialog = true;
    },
    setEditItemInfo(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.description = item.description;
      this.editDealer.mobilePhone = item.mobilePhone;
      this.editDealer.address = item.address;
      this.editDealer.uenNum = item.uenNum;
      this.editDealer.hideCarFlag = item.hideCarFlag;
      this.editDealer.authorizedFlag = item.authorizedFlag;
      this.primaryListingBannerDisplay = item.primaryListingBannerPath;
      this.secondaryListingBannerDisplay = item.secondaryListingBannerPath;
      this.tertiaryListingBannerDisplay = item.tertiaryListingBannerPath;
      this.editDealer.facebookUrl = item.facebookUrl;
      this.editDealer.websiteUrl = item.websiteUrl;
    },
    resetEditItemInfo() {
      this.editDealer.name = null;
      this.editDealer.status = null;
      this.editDealer.dealerId = null;
      this.editDealer.creditBalance = null;
      this.editDealer.logoPath = null;
      this.editDealer.description = null;
      this.editDealer.mobilePhone = null;
      this.editDealer.address = null;
      this.editDealer.uenNum = null;
      this.editDealer.hideCarFlag = null;
      this.editDealer.authorizedFlag = null;
      this.editDealer.primaryListingBannerPath = null;
      this.editDealer.secondaryListingBannerPath = null;
      this.editDealer.tertiaryListingBannerPath = null;
      this.primaryListingBannerDisplay = null;
      this.secondaryListingBannerDisplay = null;
      this.tertiaryListingBannerDisplay = null;
      this.imgFile = null;
    },
    resetAddItemInfo() {
      this.addDealer.name = null;
      this.addDealer.status = null;
      this.addDealer.dealerId = null;
      this.addDealer.creditBalance = null;
      this.addDealer.logoPath = null;
      this.addDealer.description = null;
      this.addDealer.mobilePhone = null;
      this.addDealer.address = null;
      this.addDealer.uenNum = null;
      this.addDealer.hideCarFlag = null;
      this.addDealer.authorizedFlag = null;
      this.addDealer.primaryListingBannerPath = null;
      this.addDealer.secondaryListingBannerPath = null;
      this.addDealer.tertiaryListingBannerPath = null;
    },
    assignDealerAssociatedTypesClick(item) {
      this.assignDealerTypeData.name = item.name;
      this.assignDealerTypeData.dealerId = item.id;
      this.assignDealerTypeDialog = true;
    },
    assignAccountManagerClick(item) {
      this.assignAccountManagerData.accountManagerId = item.accountManager;
      this.assignAccountManagerData.dealerId = item.id;
      this.assignAccountManagerDialog = true;
    },
    async doEditDealer() {
      const self = this;
      try {
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          self.editDealer.logoPath = imgRes.data.data;
        }
        if (self.editDealer.primaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.primaryListingBannerPath
          );
          self.editDealer.primaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.secondaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.secondaryListingBannerPath
          );
          self.editDealer.secondaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.tertiaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.tertiaryListingBannerPath
          );
          self.editDealer.tertiaryListingBannerPath = imgRes.data.data;
        }
        self.editDealer.hideCarFlag = self.editDealer.hideCarFlag ? 1 : 0;
        self.editDealer.authorizedFlag = self.editDealer.authorizedFlag ? 1 : 0;
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/outletSystem/updParentDealer`,
          self.editDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.shortcutEditDialog = false;
            self.editNameDialog = false;
            self.editDescriptionDialog = false;
            self.editCrshortcutEditDialog = false;
            self.editPhotoDialog = false;
            self.editDialog = false;
            self.fetchDealerList();
            self.resetEditItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    fetchDealerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
        status: self.dealerStatus,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/parentDealer`,
        params
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },

    async doAddDealer(addDealer) {
      const self = this;
      try {
        if (addDealer.logoPath) {
          const imgRes = await uploadImage(addDealer.logoPath);
          addDealer.logoPath = imgRes.data.data;
        }
        if (addDealer.primaryListingBannerPath) {
          const imgRes = await uploadImage(addDealer.primaryListingBannerPath);
          addDealer.primaryListingBannerPath = imgRes.data.data;
        }
        if (addDealer.secondaryListingBannerPath) {
          const imgRes = await uploadImage(
            addDealer.secondaryListingBannerPath
          );
          addDealer.secondaryListingBannerPath = imgRes.data.data;
        }
        if (addDealer.tertiaryListingBannerPath) {
          const imgRes = await uploadImage(addDealer.tertiaryListingBannerPath);
          addDealer.tertiaryListingBannerPath = imgRes.data.data;
        }
        addDealer.authorizedFlag = 1;
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/outletSystem/addParentDealer`,
          addDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.addDialog = false;
            self.fetchDealerList();
            self.resetAddItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    deleteButtonClick(item) {
      this.delParentDealer.id = item.id;
      this.delParentDealer.name = item.name;
      this.deleteDialog = true;
    },
    doDelParentDealer() {
      const self = this;
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/delParentDealer?parentDealerId=${self.delParentDealer.id}`
      ).then(res => {
        if (res.data.httpStatus === "OK") {
          self.deleteDialog = false;
          self.fetchDealerList();
        }
      });
    },
    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          dealerId: item.id,
          hideCarFlag: item.hideCarFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchDealerList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
