<template>
  <div>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <div
      style="    display: flex;
    justify-content: space-between;"
    >
      <!-- map dealer dialog box -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Map Dealer
          </v-card-title>
          <br />
          <v-card-text>
            <v-autocomplete
              v-model="mapedDealer"
              :items="entries"
              :loading="isLoading"
              :search-input.sync="search"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-selected
              placeholder="Dealer"
              prepend-icon="mdi-database-search"
              return-object
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="doAddDealer(mapedDealer)"
              >Map</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h3>Dealers</h3>
      <v-btn class="mx-2" fab small @click="dialog = true">
        <v-icon color="indigo">mdi-plus</v-icon>
      </v-btn>
    </div>
    <br />
    <div>
      <v-simple-table height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Id
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Locality
              </th>
              <th class="text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dealerList" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.locality }}</td>
              <td>
                <v-select
                  v-model="item.status"
                  :items="statusItems"
                  label="status"
                  persistent-hint
                  return-object
                  single-line
                  @change="onStatusChange(item)"
                ></v-select>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import postAxios from "services/axios-post";
// import deleteAxios from "services/axios-delete";
import getAxios from "services/axios-get";

Vue.use(VueAxios, axios);

export default {
  props: {
    parentDealerId: String,
  },
  data() {
    return {
      type: null,
      alertMsg: null,
      dealerList: [],
      statusItems: ["APPROVED", "REJECTED", "Remove"],
      mapedDealer: null,
      dialog: false,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchDealerList(this.parentDealerId);
  },
  watch: {
    parentDealerId(newParentDealerId) {
      this.fetchDealerList(newParentDealerId);
    },
    search(val) {
      if (val.length <= 3) return;
      if (this.isLoading) return;
      this.isLoading = true;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer?searchContent=${val}`
      )
        .then(res => {
          const { count, data } = res;
          this.count = count;
          this.entries = data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  methods: {
    onStatusChange(item) {
      const status = item.status;
      if (status.includes("APPROVED")) {
        this.doEditDealerStatus(item);
      } else if (status.includes("REJECTED")) {
        this.doEditDealerStatus(item);
      } else {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/outletSystem/unmapParentDealer?dealerId=${item.id}`
        )
          .then(res => {
            console.log(res);
            this.showAlert("success", "Unmap successfully");
            this.fetchDealerList(this.parentDealerId);
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      }
    },
    fetchDealerList(parentDealerId) {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/outletSystem/dealers`, {
        parentDealerId: parentDealerId,
      })
        .then(res => {
          self.dealerList = res.data.data;
          console.log(self.dealerList);
        })
        .catch(err => {
          console.error(err);
        });
    },
    async doEditDealerStatus(item) {
      const self = this;
      const data = {
        dealerId: item.id,
        status: item.status,
      };
      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchDealerList(this.parentDealerId);
            self.showAlert("success", "Edited successfully");
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    doAddDealer(mapedDealer) {
      const dealers = [];
      dealers.push(mapedDealer.id);

      const mapedParentDealer = {
        parentDealerId: this.parentDealerId,
        dealerIds: dealers,
      };

      postAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/mapParentDealer`,
        mapedParentDealer
      )
        .then(res => {
          console.log(res);
          this.showAlert("success", "Mapped successfully");
          this.fetchDealerList(this.parentDealerId);
          this.dialog = !this.dialog;
          this.entries = [];
          this.mapedDealer = null;
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
